import vacantLandParcelService from "@/views/vacantLandParcel/vacantLandParcelService";
import { apiService, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiHosts } from "@/api/constants/apiHosts";

import { IVacantLandParcel, IVacantLandParcelNew, IVacantLandParcelQuery } from "./vacantLandParcel";
import type { AxiosResponse } from "axios";

const URL = "vacant-land-parcels";
const ENTITY_NAME = "Vacant Land Parcel";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const api = apiService<IVacantLandParcel>(apiHosts.AQMS, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 */
const getVacantLandParcels = (params: IVacantLandParcelQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getVacantLandParcelById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

type VacantLandParcelIds = {
  id?: string | number;
};
const getNewVacantLandParcel = async (ids: VacantLandParcelIds) => {
  const defaultApp = {
    data: {
      ...vacantLandParcelService.getInitialData(),
      ...ids,
    },
  } as AxiosResponse<IVacantLandParcelNew, any>;

  return defaultApp;
};

const createVacantLandParcel = (vacantLandParcel: IVacantLandParcel, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, vacantLandParcel, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateVacantLandParcel = (vacantLandParcel: IVacantLandParcel, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + vacantLandParcel.id, vacantLandParcel, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {VacantLandParcel} vacantLandParcel This cannot be a 'ref' to a vacantLandParcel
 */
const saveVacantLandParcel = (vacantLandParcel: IVacantLandParcel, config = {}) => {
  if (vacantLandParcel.id) {
    return updateVacantLandParcel(vacantLandParcel, config);
  } else {
    return createVacantLandParcel(vacantLandParcel, config);
  }
};

/**
 * @param {Int} id
 */
const deleteVacantLandParcelById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteVacantLandParcelById,
  getVacantLandParcels,
  getVacantLandParcelById,
  getNewVacantLandParcel,
  createVacantLandParcel,
  updateVacantLandParcel,
  saveVacantLandParcel,
};
