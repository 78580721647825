<template>
  <router-view />
  <BaseNotifications></BaseNotifications>
</template>

<script setup lang="tsx">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { BaseNotifications } from "@modernary/mui/components/notifications";
import { useAppNav } from "@modernary/mui/components/common";

import { routeNames } from "@/routes";

const route = useRoute();
const { setNavigationItems, setApplicationTitleComponent } = useAppNav();

const titleComponent = () => <div>AQMS</div>;
setApplicationTitleComponent(titleComponent);

// Nav items should be set one time during app initilization / config.
setNavigationItems([
  {
    label: "home",
    className: "",
    showLabel: false,
    items: [
      { label: "Home", name: routeNames.home },
      // { label: "Complaints", name: routeNames.complaints },
      // { label: "Contacts", name: routeNames.persons },
    ],
  },
  {
    label: "Vacant Lands",
    className: "", //break-before-column
    items: [{ label: "Vacant Land Parcels", name: routeNames.vacantLandParcels }],
  },
  {
    label: "Asbestos",
    className: "", //break-before-column
    items: [{ label: "Asbestos Facilities", name: routeNames.asbestosFacilities }],
  },
]);
</script>
