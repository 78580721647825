import {
  addressFromFormkit,
  addressToFormkit,
  plainAddressFromFormkit,
  plainAddressToFormkit,
} from "@modernary/mui/components/fk";

import { IAsbestosFacility, IAsbestosFacilityNew } from "@/views/asbestosFacility/asbestosFacility";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IAsbestosFacility) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as IAsbestosFacility; //TODO: ts
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IAsbestosFacility }) {
  addAuthRequestFields(response.data);
  //TODO: TS
  return response.data;
}
function requestAdapter(data: IAsbestosFacility) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit<T extends IAsbestosFacility | IAsbestosFacilityNew>(data: T) {
  plainAddressToFormkit(data);
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: IAsbestosFacility) {
  plainAddressFromFormkit(data as IAsbestosFacility);
}
