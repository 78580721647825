import Home from "./views/Home.vue";

import { NotAuthorized, NotFound } from "@modernary/mui/components/common";
import {
  default as asbestosFacilityRoutes,
  asbestosFacilityRouteNames,
} from "./views/asbestosFacility/asbestosFacilityRoutes";
import {
  default as vacantLandParcelRoutes,
  vacantLandParcelRouteNames,
} from "./views/vacantLandParcel/vacantLandParcelRoutes";
// Troubleshoot your routes using this - https://paths.esm.dev

export const routeNames = {
  home: "Home",
  notAuthorized: "NotAuthorized",
  ...vacantLandParcelRouteNames,
  ...asbestosFacilityRouteNames,
};

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  { path: "/", component: Home, name: routeNames.home, meta: { title: "Home" } },
  {
    path: "/unauthorized",
    component: NotAuthorized,
    name: routeNames.notAuthorized,
    meta: { title: "Not Authorized" },
  },
  ...vacantLandParcelRoutes,
  ...asbestosFacilityRoutes,
  { path: "/:path(.*)", component: NotFound },
];
