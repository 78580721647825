import { addressFromFormkit, addressToFormkit } from "@modernary/mui/components/fk";

import { IVacantLandParcel, IVacantLandParcelNew } from "@/views/vacantLandParcel/vacantLandParcel";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IVacantLandParcel) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as IVacantLandParcel; //TODO: ts
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IVacantLandParcel }) {
  addAuthRequestFields(response.data);
  //TODO: TS
  return response.data;
}
function requestAdapter(data: IVacantLandParcel) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit<T extends IVacantLandParcel | IVacantLandParcelNew>(data: T) {
  addressToFormkit(data, "mail");
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: IVacantLandParcel) {
  addressFromFormkit(data as IVacantLandParcel, "mail");
}
