<template>
  <div class="h-full overflow-auto">
    <div class="">
      <div
        class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
      >
        <!-- Card Content goes here -->
        <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
          <div>
            <h1 class="text-2xl font-extrabold">
              {{ appSettingsStore.applicationTitle }}
              <span class="font-semibold">({{ appSettingsStore.pageTitleBase }})</span>
            </h1>
            <h2 class="mt-2 text-xl text-sky-500 md:mt-1">
              <span class="font-semibold"><LoginButton class="hover:underline" /></span>
              or
              <RegisterButton class="cursor-pointer font-semibold hover:underline">Register</RegisterButton>
              to get started
            </h2>
          </div>
          <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
            <LoginButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign In" />
            <BaseButton
              variant="priority"
              class="w-full sm:w-auto"
              label="Register"
              @click.prevent="() => $authn.redirectToRegister()"
            ></BaseButton>
          </div>
        </div>
      </div>
    </div>
    <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
      <MdrnAttribution />
    </footer>
  </div>
</template>

<script lang="ts">
import { InitialBadge } from "@modernary/mui/components/badge";
import { BaseButton } from "@modernary/mui/components/button";
import { MdrnAttribution, NoneFoundFlag, useAppSettingsStore } from "@modernary/mui/components/common";
import { LoginButton, LogoutButton, RegisterButton } from "@modernary/mui/utils/authentication";

import { defineComponent } from "vue";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/vue/20/solid";

// Home content for unauth'd users
export default defineComponent({
  name: "HomePublic",
  setup() {
    const appSettingsStore = useAppSettingsStore();

    return { BaseButton, appSettingsStore };
  },
  components: {
    BaseButton,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,
    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
    LogoutButton,
  },
});
</script>
